import { HeadFC, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Bountiful from "../assets/images/Bountiful-beverages.inline.svg"
import CreateBrand from "../assets/images/Create-brand.inline.svg"
import OpenConcept from "../assets/images/Open-concept.inline.svg"
import WorkFlexiblity from "../assets/images/Work-flexibility.inline.svg"
import ATamIco from "../assets/images/a-tam-ico.inline.svg"
import Arrow from "../assets/images/arrow.inline.svg"
import TamIco from "../assets/images/tam-ico.inline.svg"
import TamIco1 from "../assets/images/tam-ico1.inline.svg"
import TamIco2 from "../assets/images/tam-ico2.inline.svg"

import Contact from "../components/Contact"
import SEO from "../components/SEO"
import useSiteMetadata from "../hooks/useSiteMetadata"

const OpeningsPage = () => {
    return (
        <>
            <section className="lg:pt-[7.5rem] pt-24 lg:pb-[5.625rem] md:pb-20 pb-10">
                <div className="container">
                    <div className="title-sec m-auto w-full max-w-[57.188rem] text-center pb-12">
                        <h1 className="text-black text-[2.375rem] leading-[1] pb-5 lg:text-[3.25rem] text-center font-blancomedium">
                            Current openings
                            <span className="inline-block text-orangecol">.</span>
                        </h1>
                        <p className="text-[1.125rem] leading-[1.875rem] font-gorditaregular text-black text-center">
                            Accelerate your career with us and serve top clients from the industry
                        </p>
                    </div>
                    <div className="opening">
                        <ul className="flex justify-between flex-wrap">
                            <li className="mb-5 relative border border-white-800 rounded-[1.25rem] p-[1.875rem] w-full md:max-w-[calc(50%-0.938rem)] max-w-full flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col">
                                    <h2 className="text-lh font-gorditamedium pb-2">
                                        Backend Developer (Node JS)
                                    </h2>
                                    <p className="text-sm">Experience: 2-3 Years</p>
                                </div>
                                <Link
                                    to="https://docs.google.com/forms/d/1Cc4w4WDMDm6QGfWwp4vtoLxo02QlnBh80MrtYTIcJ8A/viewform?edit_requested=true"
                                    target="_blank"
                                    className=" "
                                >
                                    <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full ml-[0.625rem]">
                                        <StaticImage
                                            src="../assets/img/arrow-right.webp"
                                            alt=""
                                            className="max-w-[8px]"
                                        />
                                    </span>
                                </Link>
                            </li>
                            <li className="mb-5 relative border border-white-800 rounded-[1.25rem] p-[1.875rem] w-full md:max-w-[calc(50%-0.938rem)] max-w-full flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col">
                                    <h3 className="text-lh font-gorditamedium pb-2">
                                        Full Stack Developer (Node + Angular)
                                    </h3>
                                    <p className="text-sm">Experience: 3+ Years</p>
                                </div>
                                <Link
                                    to="https://docs.google.com/forms/d/1Cc4w4WDMDm6QGfWwp4vtoLxo02QlnBh80MrtYTIcJ8A/viewform?edit_requested=true"
                                    target="_blank"
                                    className=" "
                                >
                                    <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full ml-[0.625rem]">
                                        <StaticImage
                                            src="../assets/img/arrow-right.webp"
                                            alt=""
                                            className="max-w-[8px]"
                                        />
                                    </span>
                                </Link>
                            </li>
                            <li className="mb-5 relative border border-white-800 rounded-[1.25rem] p-[1.875rem] w-full md:max-w-[calc(50%-0.938rem)] max-w-full flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col">
                                    <h3 className="text-lh font-gorditamedium pb-2">
                                        QA Automation Engineer - Mobile
                                    </h3>
                                    <p className="text-sm">Experience: 2+ Years</p>
                                </div>
                                <Link
                                    to="https://docs.google.com/forms/d/1Cc4w4WDMDm6QGfWwp4vtoLxo02QlnBh80MrtYTIcJ8A/viewform?edit_requested=true"
                                    target="_blank"
                                    className=" "
                                >
                                    <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full ml-[0.625rem]">
                                        <StaticImage
                                            src="../assets/img/arrow-right.webp"
                                            alt=""
                                            className="max-w-[8px]"
                                        />
                                    </span>
                                </Link>
                            </li>

                            <li className="mb-5 relative border border-white-800 rounded-[1.25rem] p-[1.875rem] w-full md:max-w-[calc(50%-0.938rem)] max-w-full flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col">
                                    <h3 className="text-lh font-gorditamedium pb-2">
                                        MERN Stack Developer
                                    </h3>
                                    <p className="text-sm">Experience: 5 Years</p>
                                </div>
                                <Link
                                    to="https://docs.google.com/forms/d/1Cc4w4WDMDm6QGfWwp4vtoLxo02QlnBh80MrtYTIcJ8A/viewform?edit_requested=true"
                                    target="_blank"
                                    className=" "
                                >
                                    <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full ml-[0.625rem]">
                                        <StaticImage
                                            src="../assets/img/arrow-right.webp"
                                            alt=""
                                            className="max-w-[8px]"
                                        />
                                    </span>
                                </Link>
                            </li>
                            <li className="mb-5 relative border border-white-800 rounded-[1.25rem] p-[1.875rem] w-full md:max-w-[calc(50%-0.938rem)] max-w-full flex justify-between items-center">
                                <div className="flex justify-start items-start flex-col">
                                    <h3 className="text-lh font-gorditamedium pb-2">
                                        UI UX Designer
                                    </h3>
                                    <p className="text-sm">Experience: 2-3 Years</p>
                                </div>
                                <Link
                                    to="https://docs.google.com/forms/d/1Cc4w4WDMDm6QGfWwp4vtoLxo02QlnBh80MrtYTIcJ8A/viewform?edit_requested=true"
                                    target="_blank"
                                    className=" "
                                >
                                    <span className="inline-flex w-[2.125rem] h-[2.125rem] bg-primary justify-center items-center rounded-full ml-[0.625rem]">
                                        <StaticImage
                                            src="../assets/img/arrow-right.webp"
                                            alt=""
                                            className="max-w-[8px]"
                                        />
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="lg:pb-[6.875rem] md:pb-20 pb-10">
                <div className="container">
                    <div className="title-sec m-auto w-full">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium pb-5 text-center">
                            <span className="italic text-primary">Humble brags</span> in numbers
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-lg leading-[1.7rem] text-center ">
                            We're likely already your favorite by this point. But we thought you’d enjoy humble brags.
                        </p>
                    </div>
                    <ul className="grid grid-cols-2 md:grid-cols-4 gap-7 max-[480px]:grid-cols-1 justify-between flex-wrap pt-10 m-auto">
                        <li className="flex flex-col items-center justify-start rounded-[1.25rem] border border-cream px-5 pt-10 pb-7 text-center">
                            <h2 className="text-[3.25rem] leading-[3.625rem] pb-3 font-gorditamedium">3x</h2>
                            <p className="leading-[1.875rem] text-lg">Team size in <span className="font-gorditamedium italic">5 Years</span></p>
                        </li>
                        <li className="flex flex-col items-center justify-start rounded-[1.25rem] border border-cream px-5 pt-10 pb-7 text-center">
                            <h2 className="text-[3.25rem] leading-[3.625rem] pb-3 font-gorditamedium">11</h2>
                            <p className="leading-[1.875rem] text-lg">Years since <span className="font-gorditamedium italic">inception</span></p>
                        </li>
                        <li className="flex flex-col items-center justify-start rounded-[1.25rem] bg-[url('../assets/img/mask.webp')] bg-no-repeat bg-cover px-5 pt-10 pb-7 text-center">
                            <h2 className="text-[3.25rem] leading-[3.625rem] pb-3 font-gorditamedium">4.9</h2>
                            <p className="leading-[1.875rem] text-lg">Average <span className="font-gorditamedium italic">glassdoor rating</span></p>
                        </li>
                        <li className="flex flex-col items-center justify-start rounded-[1.25rem] border border-cream px-5 pt-10 pb-7 text-center">
                            <h2 className="text-[3.25rem] leading-[3.625rem] pb-3 font-gorditamedium">26</h2>
                            <p className="leading-[1.875rem] text-lg"> <span className="font-gorditamedium italic">Average age</span> of team member</p>
                        </li>
                    </ul>
                </div>
            </section>

            <Contact />
        </>
    )
}

export default OpeningsPage

export const Head: HeadFC = ({ location }) => {
    const { siteUrl } = useSiteMetadata()
    return (
        <SEO
            title="Join Team Techuz – Find Jobs in Design and Development, Sales and QA"
            canonical={`${siteUrl}${location.pathname}`}
            description="Are you experienced in developing web or mobile applications using latest frameworks and languages and looking to give new heights to your career? We are hiring, join our team!"
        />
    )
}
